<template>
  <Dialog v-model="dialog" :max-width="600" title="Really delete image?">
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <default-button
        icon="fa-trash-alt"
        color="error"
        :on="on"
        data-test="delete-image"
        >Delete image</default-button
      >
    </template>
    <v-card-text>
      You are about to delete the docker image, {{ tag.uri }}. This action
      cannot be undone. Are you sure you want to proceed?
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button
        color="error"
        :loading="loading"
        @click="deleteTag"
        data-test="delete-image-confirm"
      >
        Delete tag
      </default-button>
    </v-card-actions>
  </Dialog>
</template>

<script>
import { mutations } from "@/gql";
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";

const { deleteTag: mutation } = mutations;

export default {
  components: { DefaultButton, Dialog },
  props: ["value", "tag"],
  data: () => ({
    error: false,
    errorMessage: null,
    loading: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  methods: {
    deleteTag() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            pk: this.tag.id,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.$emit("delete");
            this.dialog = false;
          }
        });
    },
  },
};
</script>
