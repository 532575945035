<template>
  <help-dialog
    header="Pushing Docker images to your Packagr docker registry"
    :example="example"
    btn-text="View push commands"
  >
    <template v-slot:footer>
      For full details on how to build and push Docker images, please refer to
      <a href="https://docs.packagr.app">the docs</a>
    </template>
  </help-dialog>
</template>

<script>
import HelpDialog from "@/components/base/HelpDialog";

export default {
  components: { HelpDialog },
  props: ["registry"],
  computed: {
    example() {
      if (this.registry) {
        return `docker login ${this.registry.uri}
docker build -t ${this.registry.name} .
docker tag ${this.registry.name} ${this.registry.uri}:latest

docker push ${this.registry.uri}:latest
  `;
      }
      return "";
    },
  },
};
</script>
