var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"success","top":"","right":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticClass:"white--text"},[_vm._v("Copied to clipboard!")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.tags,"item-key":"name"},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fileSize")(item.size))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceDate")(item.created))+" ")]}},{key:"item.uri",fn:function(ref){
var item = ref.item;
return [_c('copy-button',{attrs:{"copy-string":item.uri}},[_vm._v(_vm._s(item.uri))])]}},{key:"item.digest",fn:function(ref){
var item = ref.item;
return [_c('copy-button',{attrs:{"copy-string":item.digest}},[_vm._v(_vm._s(item.digest.substring(0, 20))+"...")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('delete-tag-dialog',{attrs:{"tag":item},on:{"delete":function($event){return _vm.$emit('refresh')}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }