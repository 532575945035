<template>
  <v-row class="ma-4" v-if="registry">
    <page-header>
      Docker registry: {{ registry.name }}
      <template v-slot:subtitle>
        <div class="grey--text mt-3 text--darken-1">
          Registry URI:
          <span
            class="font-weight-bold primary--text"
            data-test="registry-uri"
            >{{ registry.uri }}</span
          >
        </div>
        <docker-help-dialog :registry="registry" />
      </template>
    </page-header>
    <v-col cols="12">
      <v-card tile>
        <table-header title="Docker images">
          <edit-registry-permissions :registry="registry" />
          <default-button
            data-test="refresh-btn"
            @click="$apollo.queries.registry.refetch()"
            icon="fa-sync"
            >Refresh</default-button
          >
        </table-header>
        <v-card-text>
          <docker-tags
            :tags="registry.tags"
            @refresh="$apollo.queries.registry.refetch()"
          ></docker-tags>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { queries } from "@/gql";
import DockerHelpDialog from "@/components/dialogs/DockerHelpDialog";
import DockerTags from "@/components/tables/DockerTags";
import PageHeader from "@/components/widgets/PageHeader";
import TableHeader from "@/components/widgets/TableHeader";
import DefaultButton from "@/components/widgets/DefaultButton";
import EditRegistryPermissions from "@/components/dialogs/EditRegistryPermissions";

const { registry: query } = queries;

export default {
  components: {
    EditRegistryPermissions,
    DefaultButton,
    TableHeader,
    PageHeader,
    DockerHelpDialog,
    DockerTags,
  },
  apollo: {
    registry: {
      query,
      variables() {
        return { pk: this.$route.params.id };
      },
    },
  },
};
</script>
