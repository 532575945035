<template>
  <div>
    <v-snackbar v-model="snackbar" color="success" top right>
      <span class="white--text">Copied to clipboard!</span>
    </v-snackbar>
    <v-data-table
      dense
      :headers="headers"
      :items="tags"
      item-key="name"
      class="elevation-0"
    >
      <template v-slot:item.size="{ item }">
        {{ item.size | fileSize }}
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | niceDate }}
      </template>
      <template v-slot:item.uri="{ item }">
        <copy-button :copy-string="item.uri">{{ item.uri }}</copy-button>
      </template>
      <template v-slot:item.digest="{ item }">
        <copy-button :copy-string="item.digest"
          >{{ item.digest.substring(0, 20) }}...</copy-button
        >
      </template>
      <template v-slot:item.delete="{ item }">
        <delete-tag-dialog :tag="item" @delete="$emit('refresh')" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DeleteTagDialog from "@/components/dialogs/DeleteTagDialog";
import CopyButton from "@/components/widgets/CopyButton";

import { fileSize, niceDate } from "@/filters";

export default {
  props: ["tags"],
  components: { CopyButton, DeleteTagDialog },
  filters: { fileSize, niceDate },
  data: () => ({
    snackbar: false,
    headers: [
      {
        text: "Tag",
        value: "name",
      },
      {
        text: "Size",
        value: "size",
      },
      {
        text: "Date pushed",
        value: "created",
      },
      {
        text: "URI",
        value: "uri",
      },
      {
        text: "Digest",
        value: "digest",
      },
      {
        text: "Delete",
        value: "delete",
      },
    ],
  }),
};
</script>
